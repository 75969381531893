import axios from "axios";
import type { AxiosResponse, AxiosError } from "axios";
import { ServerBaseUrl } from "@/utils/constant";

const request = axios.create({
  baseURL: ServerBaseUrl,
  timeout: 10000,
});

type allType = number | string | boolean | null | undefined | object;

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // // 添加请求头等前置处理
    // config.headers["Authorization"] =
    //   "Bearer" + " " + localStorage.getItem("token");
    return config;
  },
  (error) => {
    // 请求错误处理
    console.log("Request Error:", error);
    return error;
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response: AxiosResponse) => {
    // 响应后处理
    if (response.status === 200 && response.data.code === 200) {
      return response;
    }
    return response;
  },
  (error: AxiosError) => {
    console.log("Response Error:", error);
    return error;
  }
);

// get 请求封装
export const getRequest = async (
  url: string,
  params?: Record<string, allType>
) => {
  const res = await request.get(url, { params });
  return res.data;
};

// post 请求封装
export const postRequest = async (
  url: string,
  data: Record<string, allType>
) => {
  const res = await request.post(url, data);
  return res.data;
};

// put 请求封装
export const putRequest = async (
  url: string,
  { data }: { data: Record<string, allType>; params?: Record<string, allType> }
) => {
  const res = await request.get(url, { data });
  return res.data;
};

// delete 请求封装
export const deleteRequest = async (
  url: string,
  data: Record<string, allType>
) => {
  const res = await request.get(url, { data });
  return res.data;
};

export default request;
