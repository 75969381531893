import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/home/home.vue";
import Policy from "../views/policy/policy.vue";
import Protocols from "../views/protocols/protocols.vue";
import Help from "../views/help/Help.vue";
import HelpDetail from "../views/helpDetail/HelpDetail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/policy",
    name: "policy",
    component: Policy,
  },
  {
    path: "/protocols",
    name: "protocols",
    component: Protocols,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
  {
    path: "/helpDetail",
    name: "HelpDetail",
    component: HelpDetail,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
