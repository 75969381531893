import { getRequest } from "@/utils/request";

// windows下载
export const downloadApi = () =>
  getRequest("/flyrat/sysSoftwareUpdate/latestLink");

// 获取帮助中心内容列表
export const helpApi = (params: { pageSize: number; pageNum: number }) =>
  getRequest("/flyrat/FAQ/webList", params);

// 获取帮助详情
export const helpDetailApi = (id: string) => getRequest(`/flyrat/FAQ/${id}`);
