import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
// import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "element-plus/dist/index.css";
import "./styles/common.less";
import axios from "@/utils/request";

const app = createApp(App);
app.use(store);
app.use(ElementPlus, {
  // locale: zhCn,
});
app.use(router);
app.mount("#app");
app.config.globalProperties.$axios = axios;
